.textWrapper {
  height: 50px;
  width: 100%;
  display: flex;
  padding: 44px 47px;
  margin-top: 3px;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.text {
  font-family: "Lato";
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.34em;
  text-transform: uppercase;
  white-space: nowrap;
}

.dropdown {
  width: 320px;
}

.container {
  max-width: 89%;
  margin: 0 auto;
  padding: 75px 0;
}
.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  column-gap: 10px;
  overflow-x: auto;
  padding-bottom: 30px;
}
