@import url("https://fonts.googleapis.com/css2?family=Lato&family=Nunito+Sans:wght@200;300;400;900&family=Open+Sans:wght@300;400;600&display=swap");
@font-face {
  font-family: "HelveticaNeue";
  src: url("../src/assets/fonts/HelveticaNeue/helveticaneue.ttf")
    format("truetype");
}
html {
  font-size: 10px;
  --primary-color: #00587c;
  --select-border: #7aabde;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}
a {
  text-decoration: none;
}
ul li {
  list-style: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Lato",
    "Open Sans" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*:focus {
  outline: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000000;
  opacity: 0.4; /* Firefox */
  font-weight: 400;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
