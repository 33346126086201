.dropDownContainer {
  margin: 20px 0;
  position: fixed;
  position: -webkit-sticky; /* Safari */
}
.listItemsWrapper {
  display: flex;
  flex-direction: column;
}

.listItems {
  font-family: "Lato";
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.34em;
  text-transform: uppercase;
  color: #000000;
  padding: 10px 5px;
  cursor: pointer;
}
.activeLink {
  color: #00629b;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 56px;
}
.name {
  font-family: "Lato";
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  letter-spacing: 0.34em;
  text-transform: uppercase;
  color: #00629b;
  padding: 5px;
}
.actionbutton {
  margin-right: 12px;
}
.weekCardContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 30px 60px;
  column-gap: 20px;
  overflow-y: auto;
  padding-bottom: 20px;
}
.weekCardStyles {
  margin-top: 20px;
}
.weekCardBox {
  height: 130px;
  width: 130px;
  cursor: pointer;
  box-sizing: border-box;
  border: 4px solid #00587c;
  border-radius: 4px;
  background-color: white;
}
.weekCardBox:hover,
.weekCardBox:focus {
  box-sizing: border-box;
  border: 4px solid #00587c;
  border-radius: 4px;
  background-color: white;
}

.feedbackContainer {
  display: flex;
  flex-direction: column;
  margin: 5px 0px 0px 56px;
  row-gap: 20px;
}
.feedbackTitle {
  font-family: "Lato";
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.34em;
  text-transform: uppercase;
  color: #00629b;
}
.scrollbar {
  height: 520px;
  overflow-x: hidden;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #02719c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #01648b;
}
.emojis {
  height: 38px;
  width: 38px;
  text-align: center;
}
.comments {
  font-family: "Lato";
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.34em;
  text-transform: capitalize;
  color: #000000;
}

.users {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lato";
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.34em;
  text-transform: capitalize;
  color: #000000;
}
