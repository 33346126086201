.header {
  width: 100%;
  height: 142px;
  background-color: #f3f3f3;
}
.container {
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 10px;
}

.logoContainer {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoContainer img {
  max-width: 130px;
  max-height: 130px;
}

.title {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #00587c;
  letter-spacing: 0.51em;
  text-transform: uppercase;
  padding: 5px;
  text-align: center;
}
.inputContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.buttonContainer {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.inputPadding {
  padding: 8px 0;
}
