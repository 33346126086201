.weekCard {
  height: 80px;
  width: 110px;
}

.textStyle {
  font-family: "Lato";
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.34em;
  text-transform: uppercase;
  color: #000000;
}
.countContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 6px;
}

.countFontStyles {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 400;
  font-size: 25.347px;
  line-height: 30px;
}
.initialCount {
  color: #00587c;
}
actualCount {
  color: #7aabde;
}
.horizontalLine {
  width: 2px;
  height: 48px;
  background-color: #fff;
}
