.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  line-height: 13px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3sec;
  height: 38px;
  background-color: var(--primary-color);
  color: #ffffff;
  border-radius: 6px;
  border-style: none;
}
.btn:hover {
  filter: brightness(90%);
}

.large {
  width: 300px;
}
.medium {
  width: 250px;
}
.small {
  width: 120px;
}
