.aboutSection {
  text-align: left;
  background-color: #687288;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  height:100vh;
}

.header {
  overflow: hidden;
  background-color: #262a32;
  padding: 20px 10px;
  text-align: center;
}