.homepage {
  background: #f3f3f3;
  height: 100vh;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 75px;
  margin-top: 49px;
  width: 120px;
  cursor: pointer;
}

.logoContainer img {
  max-width: 112px;
  max-height: 112px;
}

.exportContainer{
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  padding: 10px;

}
.buttonContainer {
  display: flex;
  justify-content: right;
  column-gap: 30px;
  width: 100%;
}
.title {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #00587c;
  letter-spacing: 0.51em;
  text-transform: uppercase;
  padding: 5px;
  text-align: center;
}
.spanStyle {
  font-family: "Lato";
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  letter-spacing: 0.34em;
  text-transform: uppercase;
  padding: 5px;
  text-align: center;
}

.spanStyle {
  display: inline-block;
  position: relative;
  padding-bottom: 3px;
  padding: 12px;
  white-space: nowrap;
}
.spanStyle:after {
  content: "";
  display: block;
  margin: 1px;
  height: 3px;
  width: 0px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}
.spanStyle:hover:after {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.sidebar {
  margin-top: 35px;
  width: 100%;
}
.sidebar ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
}

.navLink {
  padding: 8px;
  width: 100%;
}
.navLinkContainer {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.navLink h5 {
  font-family: "Lato";
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.34em;
  text-transform: uppercase;
  color: #000000;
  margin-top: 2px;
}
.navLink h5 span {
  margin-left: 9px;
}
.activeLink {
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 8px;
}
