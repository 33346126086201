.title,
.reviewTitle {
  font-family: "Lato";
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.34em;
  text-transform: uppercase;
  color: #000000;
  padding: 10px;
}

.description {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 9px;
  line-height: 13px;
  color: #000000;
  text-align: justify;
  text-justify: inter-word;
  padding: 10px;
  overflow-wrap: break-word;

}
.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex_column {
  flex-direction: column;
}
.before_meditation,.after_meditation{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height:80px;
}