select {
  /* styling */
  background-color: white;
  border: thin solid #7aabde;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  width: 85%;
  height: 52px;
  font-size: 12px;
  text-transform: capitalize;
  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #7aabde;
}

select.selectDropDown {
  background-image: linear-gradient(45deg, transparent 50%, #7aabde 50%),
    linear-gradient(135deg, #7aabde 50%, transparent 50%),
    linear-gradient(to right, #7aabde, #7aabde);
  background-position: calc(100% - 20px) calc(1.6em + 2px),
    calc(100% - 15px) calc(1.6em + 2px), calc(100% - 3.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 3.5em;
  background-repeat: no-repeat;
}
.dropdown {
  margin-left: 30px;
}
select.selectDropDown:focus {
  border-style: blue;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
option {
  background-color: #ffffff;
  line-height: 15px;
  color: #7aabde;
  height: 120px;
  overflow: hidden;
  font-size: 14px;
  font-family: "Lato";
  text-transform: capitalize;
}
option:empty {
  display: none;
}
