.textInput {
  font-family: "FreightSansProBook-Regular", sans-serif;
  font-size: 1.2rem;
  border-radius: 2px;
  border: 0.5px solid #b0b0b0;
  padding-left: 5px;
  font-weight: 500;
  color: black;
  height: 40px;
  width: 230px;
  text-align: center;
}

.textInput::placeholder {
  /* color: rgb(80,80,80);*/
  color: #181818;
  opacity: 1;
  font-weight: 400;
  text-align: center;
}

.error {
  border: 0.5px solid #b20606;
}
.error::placeholder {
  opacity: 1; /* Firefox */
  font-weight: 400;
}

.textInput:focus::placeholder {
  color: rgb(80, 80, 80);
}
